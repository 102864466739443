// _variables
@import "./_variables";
@import "./_extension";
@import "./_brands";

// bootstrap
@import "~bootstrap/scss/bootstrap";

html, body {
    scroll-behavior: smooth;
}

.w-640 {
    max-width: 640px !important;
}

#logo-img:hover img {
    visibility: hidden;
}

#header-auth a {
    color: #ffffff !important;
    text-decoration: none;
}

#header-auth a:hover {
    color: #ffffff;
    text-decoration: underline;
}

.gameblock {
    width: 100%;
    min-height: 200px;
    max-height: 400px;
    background-repeat: no-repeat;
    transition: 0.5s height;
    height: 200px;
}

.game-expand {
    height: 100%;
    width: 0px;
    background-color: #d1d5db;
    transition: 1s width;
}

.game-expand-wrapper {
    position: absolute;
    height: 5px;
    width: 100%;
    bottom: 0;
    left: 0;
    transition: 0s height;
    transition-delay: 1s;
}

.gameblock a {
    width: 100%;
    height: 200px;
    float: left;
    transition: 0.5s height;
}

.game-title {
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    position: absolute;
    bottom: 0;
    line-height: 30px;
    font-size: 1.2rem;
}

.gameblock-wrapper {
    position: relative;
    height: 200px;
    width: 100%;
    transition: 0.5s height;
}

.gameblock:hover {
    height: 400px;
    transition: 0.5s height ease 1s !important;
}

.gameblock:hover a {
    height: 400px;
    transition-delay: 1s;
}

.gameblock:hover .gameblock-wrapper {
    height: 400px;
    transition-delay: 1s;
}

.gameblock:hover .game-expand {
    width: 100%;
    transition-delay: 0s;
}

.gameblock:hover .game-expand-wrapper {
    transition-delay: 1s;
    height: 0px;
}

.game-header {
    position: relative;
    height: 280px;
}

.game-header-text {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
}

.btn-width {
    min-width: 150px;
}

.min-w-300 {
    min-width: 300px !important;
}

.w-300 {
    width: 300px !important;
}

.btn-black {
    color: #6C757D;
}

.btn-black:hover {
    color: #ffffff;
    background-color: #1a202c !important;
}

kbd {
    margin: 0 0.2rem;
}

.btn-body:focus-visible, .btn-body:focus, .btn-body:active, .btn-body {
    border: 0;
    outline: none;
}
