.facebook {
    background-color: #1877f2 !important;
    color: #ffffff !important;
    &:hover {
        background-color: darken(#1877f2, 10%) !important;
    }
}

.twitter {
    background-color: #1da1f2 !important;
    color: #ffffff !important;
    &:hover {
        background-color: darken(#1da1f2, 10%) !important;
    }
}

.reddit {
    background-color: #ff4500 !important;
    color: #ffffff !important;
    &:hover {
        background-color: darken(#ff4500, 10%) !important;
    }
}
